import React, { useEffect, useState } from 'react';
// import LoadingOverlay from 'react-loading-overlay'

import { consultoriaService } from '../service';
import Leiaute from './Leiaute';

const Consultoria = props => {
  const [consultorias, setConsultorias] = useState([]);
  const [processando, setProcessando] = useState(false);

  useEffect(() => {
    setProcessando(true);
    consultoriaService.obterLista()
      .then(lista => setConsultorias(lista))
      .catch(ex => console.error(ex.message, ex.err))
      .finally(() => setProcessando(false));
  }, []);

  return (
    // <LoadingOverlay active={processando} spinner text='Carregando...'>
      <section className="features14 cid-rcMM0jPgsZ" id="o-que-fazemos">
        <Leiaute abaAtiva="abaConsultoria" className="container align-center">
          <h2 className="mbr-section-title pb-3 mbr-fonts-style display-2">
            Transformamos grandes ideias em grandes negócios
          </h2>
          <h3 className="mbr-section-subtitle pb-5 mbr-fonts-style display-5">
            Somos um parceiro em soluções inovadoras e sustentáveis nos seguintes segmentos:
          </h3>
          <div className="media-container-column">
            <div className='row justify-content-left'>
            { consultorias.map(c => (
                <div className="card p-4 col-12 col-md-6 col-lg-4" key={ c.id }>
                  <div className="media pb-3" style={{height: "116px"}}>
                      <div className="card-img align-self-start">
                          <span className="mbr-iconfont"><i className={c.icone}></i></span>
                      </div>
                      <div className="media-body align-self-start">
                          <h4 className="card-title py-2 align-left mbr-fonts-style display-5">{ c.area }</h4>
                      </div>
                  </div>
                  <div className="card-box align-left">
                      <p className="mbr-text mbr-fonts-style display-7 align-self-baseline">{ c.descricao }</p>
                  </div>
                </div>
              ))
            }  
            </div>
          </div>
        </Leiaute>
      </section>
    // </LoadingOverlay>
  );
}

export default Consultoria;