import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
// import LoadingOverlay from 'react-loading-overlay'

import { contatoService } from '../service';
import Leiaute from './Leiaute';
import Mensagem from './Mensagem';

const Contato = () => {
  const [processando, setProcessando] = useState(false);
  const [mensagem, setMensagem] = useState(null);
  // const campoNomeRef = useRef(null);
  const form = useForm();

  // useEffect(() => campoNomeRef.current.focus(), []);

  const processarFormulario = campos => {
    enviarForm(campos)
  }

  const enviarForm = campos => {
    setProcessando(true);
    contatoService.enviar(campos)
      .then(() => {
        form.reset();
        setMensagem({
          texto: 'Agradecemos por sua mensagem, ela será respondida pelo nosso time',
          tipo: 'sucesso'
        });
      })
      .catch(() => setMensagem({
        texto: 'Houve um erro temporário no envio da mensagem, tente novamente por favor',
        tipo: 'erro'
      }))
      .finally(() => setProcessando(false));
  }
  
  return (
    // <LoadingOverlay active={processando} spinner text='Enviando...'>
      <section className="mbr-section form1 cid-rcMKopAj5F" id="contato">
        <div className="container">
            <div className="row justify-content-center">
                <div className="title col-12 col-lg-8">
                    <h2 className="mbr-section-title align-center pb-3 mbr-fonts-style display-2">
                      TEM UMA PERGUNTA?
                    </h2>
                    <h3 className="mbr-section-subtitle align-center mbr-light pb-3 mbr-fonts-style display-5">
                      Deixe-nos uma mensagem, retornaremos seu contato.
                    </h3>
                </div>
            </div>
        </div>
        <Leiaute abaAtiva="abaContato">
          <div className="row justify-content-center">
            <div className="media-container-column col-lg-8" data-form-type="formoid">
              { mensagem?.texto && <Mensagem {...mensagem}/> }
              <form className="mbr-form" id="formContato" onSubmit={form.handleSubmit(processarFormulario)}>
                <div className="row row-sm-offset">
                  <div className="col-md-12 multi-horizontal" data-for="nome">
                    <div className="form-group">
                      <label htmlFor="nome">Nome</label>
                      <input id="nome" className="form-control" name="nome" type="text" ref={form.register({required: true})}/>
                      {form.errors.nome?.type === 'required' && <small style={{color: 'red'}}>Informe seu nome</small>}
                    </div>
                  </div>
                </div>
                <div className="row row-sm-offset">
                  <div className="col-md-8 multi-horizontal" data-for="email">
                    <div className="form-group">
                      <label htmlFor="email">e-Mail</label>
                      <input id="email" className="form-control" name="email" type="email" ref={form.register({required: true})}/>
                      {form.errors.email?.type === 'required' && <small style={{color: 'red'}}>Informe seu e-mail</small>}
                    </div>
                  </div>
                  <div className="col-md-4 multi-horizontal" data-for="telefone">
                    <div className="form-group">
                      <label htmlFor="telefone">Telefone (com DDD)</label>
                      <input id="telefone" className="form-control" name="telefone" type="tel" ref={form.register}/>
                    </div>
                  </div>
                </div>
                <div className="form-group" data-for="mensagem">
                  <label classame="form-control-label mbr-fonts-style display-7" htmlFor="mensagem">Mensagem:</label>
                  <textarea id="mensagem" className="form-control" rows="7" data-form-field="Mensagem" style={{resize: 'none'}} name="mensagem" type="text" ref={form.register({required: true})}></textarea>
                  {form.errors.mensagem?.type === 'required' && <small style={{color: 'red'}}>Digite sua mensagem</small>}
                </div>
                <span className="input-group-btn">
                  <button type="submit" className="btn btn-primary btn-form display-4">Enviar</button>
                </span>
              </form>
            </div>
          </div>
        </Leiaute>
      </section>
    // </LoadingOverlay>
  );
}

export default Contato;