import { arquivosBaseUrl } from '../config';
import { ServiceErrorException } from './index';

const url =  `${arquivosBaseUrl}/72b2f49383075f82a585ca6af577d919`;

const obterLista = () => fetch(url, {method: 'GET', headers: {Accept: 'application/json'}})
  .then(response => response.json())
  .then(json => JSON.parse(json.files['portfolio.json'].content))
  .catch(err => {
    throw new ServiceErrorException('Não foi possível recuperar a lista do portfolio', err);
  });
  
export { obterLista };
