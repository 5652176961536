import React, { useState, useEffect } from 'react';
// import LoadingOverlay from 'react-loading-overlay'

import Leiaute from './Leiaute';
import { portfolioService } from '../service';

const Portfolio = () => {
  const [portfolio, setPortifolio] = useState([]);
  const [processando, setProcessando] = useState(false);

  useEffect(() => {
    setProcessando(true);
    portfolioService.obterLista()
      .then(lista => setPortifolio(lista))
      .catch(ex => console.error(ex.message, ex.err))
      .finally(() => setProcessando(false));
    }, []);

  return (
    // <LoadingOverlay active={processando} spinner text='Carregando...'>
      <section className="clients cid-rcMLAC4SOw" id="principais-projetos">
        <Leiaute>
          <div className="container mb-5">
            <div className="media-container-row">
              <div className="col-12 align-center">
                <h2 className="mbr-section-title pb-3 mbr-fonts-style display-2">
                    Nossos principais projetos
                </h2>
              </div>
            </div>
          </div>
          <div className="container">
            { portfolio.map(proj => renderPortfolio(proj)) }
          </div>
        </Leiaute>
      </section>
    // </LoadingOverlay>
    );
}

const renderPortfolio = proj => (
  <div className="row pb-2" key={ proj.id }>
    <div className="col-xs-9 col-md-6 text-right">
      <img alt='' src={ proj.logo.url } width={ proj.logo.width } height={ proj.logo.height }/>
    </div>
    <div className="col-xs-3 col-md-6 align-middle">
      <div className="font-weight-bold">{ proj.descricao }</div>
      <div className="font-weight-light">{ proj.atuacao }</div>
    </div>
  </div>
);

export default Portfolio;
