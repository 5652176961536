import { arquivosBaseUrl } from '../config';
import { ServiceErrorException } from './index';

const url = `${arquivosBaseUrl}/7ea83cda92600c61e206427c9337846a`;

const obterLista = () => fetch(url, {method: 'GET', headers: {Accept: 'application/json'}})
  .then(response => response.json())
  .then(json => JSON.parse(json.files['consultorias.json'].content))
  .catch(err => {
    throw new ServiceErrorException('Não foi possível recuperar a lista de consultorias', err);
  });

export { obterLista };