import React from 'react';
import Pagina from './Pagina';
import Consultoria from './Consultoria';
import Portfolio from './Portfolio';
import Contato from './Contato';

const PaginaHome = () => (
  <Pagina>
    <section className="cid-qTkA127IK8 mbr-fullscreen" id="header2-1">
      <div className='container align-center'>
        <div className="row justify-content-md-center">
          <div className="mbr-white col-md-10">
            <h1 className="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-1">Tem uma grande ideia?</h1>
            <h3 className="mbr-section-subtitle align-center mbr-light pb-3 mbr-fonts-style display-2">Podemos te ajudar</h3>
          </div>
        </div>
      </div>
      <div className="mbr-arrow hidden-sm-down" aria-hidden="true">
        <a href="#o-que-fazemos"><i className="mbri-down mbr-iconfont"/></a>
      </div>
    </section>
    <Consultoria/>
    <Portfolio/>
    <Contato/>
  </Pagina>
);

export default PaginaHome;