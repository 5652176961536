import React, { useEffect } from 'react';
import logo from '../img/logo.png';

const Pagina = ({children}) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <section className="menu cid-qTkzRZLJNu">
        <nav className="navbar navbar-expand beta-menu navbar-dropdown align-items-center navbar-fixed-top navbar-toggleable-sm">
          <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <div className="hamburger">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
              </div>
          </button>
          <div className="menu-logo">
              <div className="navbar-brand">
                  <span className="navbar-logo"><a href="#"><img src={logo} style={{height: "3.8rem"}}/></a></span>
                  <span className="navbar-caption-wrap"><a className="navbar-caption text-white display-4" href="#">TECLATAB</a></span>
              </div>
          </div>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav nav-dropdown nav-right" data-app-modern-menu="true">
              <li id='abaConsultoria' className="nav-item">
                <a className="nav-link link text-white display-4" href="#o-que-fazemos">
                  <span className="mbri-question mbr-iconfont mbr-iconfont-btn"/>O que fazemos?
                </a>
              </li>

              <li id='abaPortfolio' className="nav-item">
                <a className="nav-link link text-white display-4" href="#principais-projetos">
                  <span className="mbri-opened-folder mbr-iconfont mbr-iconfont-btn"/>Principais projetos
                </a>
              </li>

              <li id='abaContato' className="nav-item">
                <a className="nav-link link text-white display-4" href="#contato">
                  <span className="mbri-share mbr-iconfont mbr-iconfont-btn"/>Contato
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </section>
      { children }
      <noscript>Você precisa habilitar o JavaScript para esta aplicação funcionar</noscript>
      <script src="assets/web/assets/jquery/jquery.min.js"></script>
      <script src="assets/popper/popper.min.js"></script>
      <script src="assets/tether/tether.min.js"></script>
      <script src="assets/bootstrap/js/bootstrap.min.js"></script>
      <script src="assets/dropdown/js/script.min.js"></script>
      <script src="assets/smoothscroll/smooth-scroll.js"></script>
      <script src="assets/touchswipe/jquery.touch-swipe.min.js"></script>
      <script src="assets/bootstrapcarouselswipe/bootstrap-carousel-swipe.js"></script>
      <script src="assets/theme/js/script.js"></script>
      <script src="assets/mbr-clients-slider/mbr-clients-slider.js"></script>
    </>
  );
}

export default Pagina;