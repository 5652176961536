import { apiBaseUrl } from '../config';
import { ServiceErrorException } from './index';
import FetchHelper from '../utils/FetchHelper';

const url =  `${apiBaseUrl}/contatos`;

const enviar = campos => {
  let config = {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8'
    },
    body: JSON.stringify(campos)
  };

  return fetch(url, config)
    .then(response => FetchHelper.handleErrors(response))
    .catch(err => {
      throw new ServiceErrorException('Falha no envio da mensagem', err);
    });
};

export { enviar };