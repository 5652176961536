import React, { Component } from 'react';

const tiposDeAlert = Object.freeze({
  erro: 'alert-danger',
  sucesso: 'alert-success',
  advertencia: 'alert-warning',
  info: 'alert-info'
})

const Mensagem = ({texto, tipo}) => {
  const classNameDoAlert = "alert " + tiposDeAlert[tipo ? tipo : 'erro'];
  return !texto ? null : <div className={classNameDoAlert} role="alert">{texto}</div>;
}

export default Mensagem;